<template>
  <header class="header">
    <nav class="navbar" style="background-color: white;">
      <div class="container-fluid">
        <a style="font-family:Abril Fatface Regular; text-align: left; font-weight: 800; color:#905C4C; font-size: 32px;" @click.prevent="scrollToSection('#home-section')" class="navbar-brand">
          <img src="@/assets/images/the_bothy_logo_130.png" alt="The Bothy logo" />
        </a>
        <button class="navbar-toggler" @click="collapsed = !collapsed">
          <span class="navbar-toggler-icon"></span>
        </button>
        <ul class="navbar-nav" :class="{ 'show': collapsed }">
          <li class="nav-item" v-for="(section, index) in sections" :key="index">
            <a @click.prevent="scrollToSection(section.id)" class="nav-link">{{ section.name }}</a>
          </li>
          <!-- CTA -->
          <li class="nav-item">
            <a @click.prevent="scrollToSection('#book')" class="cta nav-link">Book Now</a>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      collapsed: false,
      sections: [
        { id: '#space-section', name: 'The Space' },
        { id: '#amenities', name: 'Our Amenities' },
        { id: '#location', name: 'Our Location' },
        { id: '#local-attractions', name: 'Local Attractions' },
        { id: '#about-host', name: 'Your Host' },
        { id: '#pricing', name: 'Our Pricing' },
        { id: '#info-section', name: 'Things to Know' },
        { id: '#FAQs', name: 'FAQs' }
      ]
    };
  },
  methods: {
    scrollToSection(sectionId) {
      const section = document.querySelector(sectionId);
      if (section) {
        const offset = 70; // Adjust the offset as needed
        const topPosition = section.offsetTop - offset;
        window.scrollTo({
          top: topPosition,
          behavior: "smooth",
        });
        this.collapsed = false; // close the menu after the click
      }
    },
  },
};
</script>

<style scoped>
.navbar .container-fluid {
  justify-content: center;
}
.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
}

.container-fluid {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15%;
}

.navbar-toggler {
  display: none;
}

.navbar-nav {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
  flex-direction:row ;
}

.navbar-nav .nav-link {
  cursor: pointer;
  margin-right: 2rem;
  position: relative;
}
.navbar-nav .nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background: #2a2a2a;
  transition: width .3s;
  bottom: -5px;
  left: 0;
}

.navbar-nav .nav-link:hover::after {
  width: 100%;
}

.navbar-nav .cta {
  text-decoration: none;
  padding: 10px 20px;
  background-color: #2a2a2a;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  box-shadow: 2px 4px 12px rgba(0,0,0,.08);
}

.navbar-nav .cta:hover {
  background-color: #709E88;
}

@media (max-width: 768px) {
  .navbar-toggler {
    display: inline-block;
  }

  .navbar-nav {
    display: none;
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-nav .nav-link {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .navbar-nav .cta {
    margin-left: 0;
    box-shadow: none;
  }

  .navbar-nav.show {
    display: flex;
  }
  .container-fluid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
}
}
</style>



























