<template>
    <section class="attractions">
        <div id="local-attractions">
        <div class="container">
            <h2>Local Attractions</h2>
      <div class="attractions-grid">
        <div class="attraction-card" v-for="(attraction, index) in attractions" :key="index">
          <div class="card-image">
            <img :src="attraction.image" alt="Attraction Image" />
          </div>
          <div class="card-content">
            <h2>{{attraction.name}}</h2>
            <p>{{attraction.description}}</p>
            <div class="attraction-info">
                <i class="fas fa-car"></i>
              <span style="padding:0 5px">{{attraction.distance}}</span>
              <i class="fas fa-clock"></i>
              <span style="padding:0 5px">{{attraction.time}}</span>
              
            </div>
          </div>
        </div>
    </div>
      </div>
    </div>
    </section>
  </template>
  
  <script>
  import { ref } from 'vue';
  
  export default {
    setup() {
      const attractions = ref([
        {
          name: "Exmoor ",
          description: "With its vast expanses of rolling moorlands, enchanting river valleys, and rugged coastline, this natural wonderland offers a plethora of outdoor adventures.",
          image: require('@/assets/images/Attractions/Exmoor.jpeg'),
          distance: "8.8 miles",
          time: "20 min"
        },
        {
          name: "South Molton",
          description: "With its historic market square, quaint streets, and friendly atmosphere, South Molton offers a warm welcome to visitors.",
          image: require('@/assets/images/Attractions/south_molton.jpeg'),
          distance: "3.0 miles",
          time: "5 min"
        },
        {
          name: "Ilfracombe",
          description: "With its rugged cliffs, sandy beaches, and picturesque harbor, Ilfracombe offers a perfect blend of natural beauty and seaside charm.",
          image: require('@/assets/images/Attractions/combe.jpeg'),
          distance: "28.5 miles",
          time: "45 min"
        },
        {
          name: "Woolacombe Beach",
          description: "Spend the day at the coastal paradise of Woolacombe Beach, a beautiful stretch of golden sands nestled on the North Devon coast.",
          image: require('@/assets/images/Attractions/Wollacombe.jpeg'),
          distance: "30.2 miles",
          time: "48 min"
        },
        {
          name: "Saunton Sands",
          description: "Experience the coastal bliss of Saunton Sands, a pristine beach located on the North Devon coast.",
          image: require('@/assets/images/Attractions/sands.jpeg'),
          distance: "23.1 miles",
          time: "40 min"
        },
        {
          name: "Valley of the Rocks",
          description: "Immerse yourself in the dramatic beauty of the Valley of the Rocks, a mesmerising natural wonder located near Lynton and Lynmouth on the North Devon coast. ",
          image: require('@/assets/images/Attractions/Valley_of_the_Rocks.jpeg'),
          distance: "25.9 miles",
          time: "44 min"
        },
      ]);
  
      return {
        attractions,
      };
    },
  };
  </script>
  
  <style scoped>
  .container {
  max-width: 1080px;
  margin: 0 auto;
  padding: 80px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
 
}
  .attractions-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px; 
  }
  
  .attraction-card {
    flex-basis: calc(33.33% - 20px); 
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0,0,0,0.15);
    transition: 0.3s;
  }
  
  .attraction-card:hover {
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
  }
  
  .card-image img {
    width: 100%;
    height: auto;
  }
  
  .card-content {
    padding: 20px;
  }
  
  @media (max-width: 768px) {
    .attraction-card {
      flex-basis: 100%;
    }
  }
  </style>
  