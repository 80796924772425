<template>
  <div>
    <h1>This is a test page!</h1>
  </div>
</template>

<script>
export default {
  name: 'TestComponent',
}
</script>