<template>
    <div class="home">
      <Banner />
      <WelcomeToOurRetreat />
      <PropertyIntro />
      <OurAmenities />
      <WhereWeAre />
      <LocalAttractions />
      <AboutTheHost />
      <BothyPricing />
      <BookingCalendar />
      <ThingsToKnow />
      <FrequentlyAskedQuestions />
      
      
    </div>
  </template>
  
  <script>
  import Banner from '@/components/SiteBanner.vue';
  import PropertyIntro from '@/components/PropertyIntro.vue';
  import OurAmenities from '@/components/OurAmenities.vue';
  import LocalAttractions from '@/components/LocalAttractions.vue'
  import WhereWeAre from '@/components/WhereWeAre.vue'
  import AboutTheHost from '@/components/AboutTheHost.vue'
  import ThingsToKnow from '@/components/ThingsToKnow.vue'
  import BookingCalendar from '@/components/BookingCalendar.vue'
  import FrequentlyAskedQuestions from '@/components/FrequentlyAskedQuestions.vue'
  import WelcomeToOurRetreat from '@/components/WelcomeToOurRetreat.vue'
  import BothyPricing from '@/components/BothyPricing.vue'
  
  
  export default {
    name: 'HomePage',
    components: {
      Banner,
      PropertyIntro,
      OurAmenities,
      WhereWeAre,
      AboutTheHost,
      ThingsToKnow,
      BookingCalendar,
      FrequentlyAskedQuestions,
      LocalAttractions,
      WelcomeToOurRetreat,
      BothyPricing,
      

    },
  };
  </script>
  
  <style scoped>
  /* Add any homepage-specific styles here */
  </style>