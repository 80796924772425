<template>
    <section id="amenities" style="margin: 10% 0; background-color: #7db0984d;">
      <div class="container">
      <h2 class="amenities-title">Our Amenities</h2>
      <div class="amenities-grid">
      <!-- Basic necessities -->
      <div class="amenity">
        <i class="fas fa-wifi"></i>
        <p>Super fast 250mbps Wi-Fi</p>
      </div>
      <div class="amenity">
        <i class="fas fa-utensils"></i>
        <p>Kitchen & Cooking facilitates</p>
      </div>
      <!-- Comfort -->
      <div class="amenity">
        <i class="fas fa-fire"></i>
        <p>Central Heating & Indoor fireplace</p>
      </div>
      <!-- Parking -->
      <div class="amenity">
        <i class="fas fa-parking"></i>
        <p>Free Parking on premises</p>
      </div>
      <!-- Entertainment -->
      <div class="amenity">
        <i class="fas fa-tv"></i>
        <p>TV & Blu-ray player</p>
      </div>
      <!-- Laundry -->
      <div class="amenity">
        <i class="fas fa-tshirt"></i>
        <p>Washing Machine & Drier</p>
      </div>
      <div class="amenity">
        <i class="fas fa-burn"></i>
        <p>Clothes Iron</p>
      </div>
      <!-- Outdoor -->
      <div class="amenity">
        <i class="fas fa-umbrella-beach"></i>
        <p>Private Patio & Garden</p>
      </div>
      <!-- Bedding -->
      <div class="amenity">
        <i class="fas fa-bed"></i>
        <p>Extra Pillows & Linen</p>
      </div>
      <!-- Safety -->
      <div class="amenity">
        <i class="fas fa-smoking-ban"></i>
        <p>Smoke Alarm</p>
      </div>
    </div>
  </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'OurAmenities',
  };
  </script>
  
  
  <style scoped>
  .container {
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
  #amenities {
    padding: 0;
    text-align: center;
  }
  
  .amenities-title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 30px;
  }
  
  .amenities-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .amenity {
    width: 200px;
    padding: 20px;
    text-align: center;
  }
  
  .amenity i {
    font-size: 3rem;
    color: #2a2a2a;
  }
  
  .amenity p {
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    .amenity {
      width: calc(50% - 40px); /* Adjusting width for mobile screens. We subtract 40px considering the padding of 20px on left and right */
      margin-bottom: 20px;
    }
    .container {
  max-width: 1080px;
  margin: 0 auto;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
  }

  @media (min-width: 769px) {
    #amenities {
      padding: 80px;
    }
  }
</style>
  