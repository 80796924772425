<template>
  <div id="Home">
    <div class="container">
    <div class="banner">
      <div class="grid-container">
        <div class="grid-item main-item">
          <img src="@/assets/images/Bothy_1.png" alt="main image" />
        </div>
        <div class="grid-item">
          <img src="@/assets/images/Bothy_13.png" alt="image 1" />
        </div>
        <div class="grid-item">
          <img src="@/assets/images/Bothy_8.png" alt="image 2" />
        </div>
        <div class="grid-item">
          <img src="@/assets/images/Bothy_4.png" alt="image 3" />
        </div>
        <div class="grid-item">
          <img src="@/assets/images/Bothy_2.jpg" alt="image 4" />
        </div>
        <!-- New row of images -->
        <div class="grid-item new-image">
          <img src="@/assets/images/Bothy_9.png" alt="new image 1" />
        </div>
        
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "SiteBanner",
};
</script>

<style scoped>
.container {
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.banner {
  margin: 20px 0;
  padding-top: 0px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, auto);
  gap: 10px;
  border-radius: 25px;
  overflow: hidden;
}

.grid-item {
  overflow: hidden;
}

.grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-item {
  grid-column: span 2;
  grid-row: span 2;
}

.new-image {
  grid-column: span 1;
  grid-row: 3;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, auto);
  }

  .main-item {
    grid-row: 1 / 3;
    grid-column: 1;
  }

  .new-image {
    grid-row: auto;
    grid-column: 1;
  }
}
</style>
