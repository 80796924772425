import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from '../App.vue';
import Home from '@/views/Home.vue'
import Test from '@/views/TestComponent.vue'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/test',
      name: 'Test',
      component: Test
    }
    // other routes...
  ]
})

createApp(App).use(router).mount('#app');

export default router;