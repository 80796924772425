<template>
    <div id="pricing">
        <div class="container">
            <h2>Pricing</h2>
    <div class="pricing-container">
      <div class="pricing-card" v-for="(price, index) in prices" :key="index">
        <div class="pricing-card-header">
          <h3>{{ index === 0 ? '1 Night' : index === 1 ? '3 Nights' : '7 Nights' }}</h3>
        </div>
        <div class="pricing-card-content">
          <div class="pricing-card-price">
            <h2>£{{ price }}</h2>
          </div>
        </div>
      </div>
    </div>
</div>
</div>
  </template>
  
  <script>
  export default {
    name: 'BothyPricing',
    data() {
      return {
        prices: [100, 270, 600], // Replace these with your actual prices
      }
    },
  };
  </script>
  
  <style scoped>
    .container {
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 10% ;
}
  .pricing-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    
  }
  
  .pricing-card {
    width: 300px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 25px;
  }
  
  .pricing-card-header {
    background-color: #7DB098;
    color: #fff;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding: 20px 0;
  }
  
  .pricing-card-content {
    padding: 20px;
    background-color: white;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;

  }
  
  .pricing-card-price {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
  }
  </style>