<template>
    <div id="about-host">
      <div class="container">
    <section class="host-details" style="margin: 10% 0;">
        <h2 class="host">About the Host</h2>
      <div class="flex-container">
        <div class="profile-card">
          <div class="card-columns">
            <div class="profile-image">
              <img :src="trevorImage" alt="Profile of Trevor">
              <h2>Trevor</h2>
            </div>
            <div class="profile-stats">
              <p>Reviews on Airbnb</p>
              <hr>
              <p>4.96 &#8902;Airbnb Rating</p>
              <hr>
              <p>4 Years Hosting</p>
            </div>
          </div>
          <div class="about-grid">
      <div><i class="fas fa-briefcase"></i> Entrepreneur</div>
      <div><i class="fas fa-guitar"></i> Blues Guitar Aficionado</div>
      <div><i class="fas fa-globe"></i> Avid Traveller</div>
      <div><i class="fas fa-grin"></i> Loves Comedy</div>
      <div><i class="fas fa-utensils"></i> Italian Food Lover</div>
      <div><i class="fas fa-home"></i> Lives in Devon</div>
    </div>
        </div>
        <div class="about-host">
          
    <p style="text-align: left;">
      Hi, I'm Trevor, a business owner in my mid-fifties. I'm semi-retired, have two grown-up kids and live a fulfilling life with my wife in Devon. I'm passionate about blues guitar and live music. I'm also an avid traveler, always ready for a new adventure. I love a good laugh, especially with a great comedy. When I'm not exploring new places or immersed in music, you can find me savoring Italian food. Above all, I'm polite, down to earth, and have a strong sense of humor. Looking forward to being your host!
    </p>
        </div>
      </div>
    </section>
</div>
</div>
  </template>
  
  <script>
  import trevorImage from '@/assets/images/trevor.png'

  export default {
    name: 'AboutTheHost',
    data() {
        return {
            trevorImage
        }
    }
  };
  </script>
  
  <style scoped>
  .container {
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
  #about-host {
    padding: 0;
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .profile-card {
    padding: 5%;
    border-radius: 25px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    background-color: #7db0984d;
  }
  
  .card-columns {
    display: flex;
    justify-content: space-between;
  }
  
  .profile-image img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .about-host {
    padding: 20px;
    font-size: 1.2em;
    border-radius: 25px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    background-color: #7db0984d;
  }
  
  .about-host h3 {
    margin-bottom: 10px;
  }
  
  .about-host ul {
    margin-bottom: 20px;
  }
  
  .about-host ul li {
    margin-bottom: 10px;
  }
  
  .about-host ul li i {
    margin-right: 5px;
  }

  .host {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 30px;
  }

  .about-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin: 5% 0;
    text-align: left;
  }
 
  /* For devices with width greater than 768px */
  @media only screen and (min-width: 768px) {
    #about-host {
      padding: 0px;
    }

    .flex-container {
      flex-direction: row;
    }
  
    .profile-card {
      flex: 1;
      margin-right: 20px;
    }
  
    .about-host {
      flex: 2;
    }
  }
</style>
