<template>
  <section class="property-intro">
    <div class="container">
      <div id="space-section">
        <h2>The Space</h2>
        <div class="row">
          <div class="column text">
            <p>Thoughtfully revitalised in 2021, The Bothy serves as a serene retreat, thoughtfully designed to provide couples with a sense of peace and solace. This completely self-sustained refuge is equipped with all the familiar comforts of home, ensuring you experience a restful and cozy stay. The full-featured kitchen facilitates culinary adventures, while the cozy dining area fosters intimate meals. A separate bedroom features a luxurious king-size bed, promising restful slumbers and sweet dreams.</p>
          </div>
          <div class="column image">
            <img src="@/assets/images/Bothy_11.png" alt="The Space" />
          </div>
        </div>
        <div class="row reverse-row">
          <div class="column image">
            <img src="@/assets/images/Bothy_13.png" alt="The Space" />
          </div>
          <div class="column text">
            <p>The lounge, enhanced by the warmth of a wood-burning stove, includes a plush sofa, an ideal nook for curling up with a book or enjoying a movie. A wall-mounted flat-screen TV, accompanied by a DVD player and a diverse DVD library, provides an assortment of entertainment options. For guests wishing to remain connected, high-speed Wi-Fi access is readily available.</p>
          </div>
        </div>
        <div class="row">
          <div class="column text">
            <p>Tucked away in the beautiful hamlet of Alswear, North Devon, The Bothy blends history with modern comforts. The village is steeped in history, offering a peaceful and idyllic setting nestled in the lush Mole River Valley, close to the distinguished beauty of Exmoor. Alswear provides the perfect launching point for exploring the breath-taking coasts of North Devon and Somerset, both merely a thirty-minute drive away. It's a region famed for its unspoiled landscapes, offering countless opportunities for hiking, cycling, wildlife spotting, and much more.</p>          </div>
          <div class="column image">
            <img src="@/assets/images/Bothy_12.png" alt="The Space" />
          </div>
        </div>
        <div class="row reverse-row">
          <div class="column image">
            <img src="@/assets/images/Bothy_10.png" alt="The Space" />
          </div>
          <div class="column text">
            <p>Discover the enchanting allure of The Bothy, where the captivating spirit of rural Devon comes alive in harmony with the modern comforts of a luxurious home. Embrace the serenity and natural beauty of this idyllic retreat, nestled amidst the picturesque landscapes of North Devon. Immerse yourself in the warmth of its inviting ambiance, where every detail has been thoughtfully curated to ensure an unforgettable holiday experience. Prepare to unwind and rejuvenate in a setting that seamlessly blends the timeless charm of the past with the indulgent amenities of the present, promising an escape that is both captivating and rejuvenating</p>          </div>
        </div>
        <!-- Continue alternating between rows and reverse-rows for each pair of paragraphs and images -->
      </div>
      </div>
  </section>
</template>

<script>
export default {
  name: 'PropertyIntro',
};
</script>

<style scoped>

.row,
.reverse-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}



.row-column,
.two-column-section-column {
  flex: 1;
  padding: 10px;
}

.row-column.text,
.two-column-section-column.text {
  align-self: center;
}

.row-column.image,
.two-column-section-column.image {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 4px 12px rgba(0,0,0,.08)
}

.row-column.image img,
.two-column-section-column.image img {
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top:2%;
}
.intro-section {
  text-align: center;
  padding: 20px 0;
  background-color: #7db0984d;
  border-radius: 20px;
  box-shadow: 2px 4px 12px rgba(0,0,0,.08);
}

.intro-title {
  font-size: 2.5rem;
  color: #333;
}

.intro-description {
  font-size: 1.2rem;
  color: #666;
  margin: 20px 0;
  padding: 5%;
}

.cta {
  text-decoration: none;
  padding: 10px 20px;
  background-color: #ff5a5f;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta:hover {
  background-color: #ff494d;
}

.two-column-section {
  display: flex;
  gap: 20px;
}

.two-column-section-column {
  flex: 1;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
}

.two-column-section-column h2 {
  font-size: 1.5rem;
  color: #333;
}

.two-column-section-column p {
  font-size: 1.1rem;
  color: #666;
}

.two-column-section-column img {
  max-width: 100%;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .row,
  .reverse-row,
  .two-column-section {
    flex-direction: column;
  }
  .container {
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top:10%;
}

  .row-column,
  .two-column-section-column {
    flex: none;
  }
  .intro-description {
    padding: 0px;
  }
}
.column {
  flex: 1;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
}

.sub-section {
  flex: 0;
}

.column h2 {
  font-size: 1.5rem;
  color: #333;
}

.column p {
  font-size: 1.1rem;
  color: #666;
}

.column img {
  max-width: 100%;
  border-radius: 10px;
}

.about-section {
  display: flex;
  gap: 20px;
}

.about-section .column {
  flex: 1;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>