<template>
  <div id="location">
    <div class="container">
  <h2 class="location">Our Location</h2>
  <div class="map-container">
    <GoogleMap
      api-key="AIzaSyBINoKMfr5LNGvFahHJM6meyGmvlKrRsz4"
      class="google-map"
      :center="center"
      :zoom="8"
      :options="{styles: mapStyles}"
    >
      <Marker :options="{ position: center }" />
    </GoogleMap>
  </div>
  </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";

export default defineComponent({
  components: { GoogleMap, Marker },
  setup() {
    const center = { lat: 50.983501, lng: -3.820797 };

    const mapStyles = [
      {
        featureType: "all",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "road",
        elementType: "all",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit",
        elementType: "all",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi",
        elementType: "all",
        stylers: [{ visibility: "off" }],
      },
    ];

    return { center, mapStyles };
  },
});
</script>

<style scoped>
.container {
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.map-container {
  border-radius: 25px;
  overflow: hidden;
}

.google-map {
  width: 100%;
  height: 500px;
}
.location {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 30px;
}
</style>