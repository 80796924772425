<template>
  <div id="app" style="background-color:white">
    <SiteHeader />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import SiteHeader from './components/SiteHeader.vue'
import Footer from './components/Footer.vue'


export default {
  name: 'App',
  components: {
    SiteHeader,
    Footer,

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* Responsive Padding */
@media (max-width: 767px) {
  #app {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  #app {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media (min-width: 1200px) {
  #app {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
</style>
