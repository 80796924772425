<template>
    <footer class="footer">
      <div class="container">
        <p>&copy; 2023 Relax@TheBothy. All rights reserved.</p>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'SiteFooter',
  };
  </script>
  
  <style scoped>
  /* Add your CSS styles for the footer here */
  </style>