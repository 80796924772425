<template>
    <section class="property-intro">
      <div class="container">
        <div class="intro-section">
          <h1 class="intro-title">Welcome to Our Retreat</h1>
          <p class="intro-description">
            Immerse yourself in over 250 years of history at The Bothy, an elegantly converted barn and forge nestled in the picturesque hamlet of Alswear in North Devon. With its high-quality finishes and an enchanting location in the verdant Mole River Valley near Exmoor, The Bothy offers an ideal starting point for journeys of exploration to the stunning coasts of North Devon and Somerset. All this is less than a 30-minute drive, allowing easy access to the breathtaking landscapes that surround you.
          </p>
          
        </div>
        </div>
        </section>
        </template>

<style scoped>
  .container {
    max-width: 1080px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 2%;
  }
  .intro-section {
    text-align: center;
    padding: 20px 0;
    background-color: #7db0984d;
    border-radius: 20px;
    box-shadow: 2px 4px 12px rgba(0,0,0,.08);
  }
  
  .intro-title {
    font-size: 2.5rem;
    color: #333;
  }
  
  .intro-description {
    font-size: 1.2rem;
    color: #666;
    margin: 20px 0;
    padding: 5%;
  }

  @media (max-width: 768px) {
    .intro-description {
      padding: 5%;
    }
    
  }
</style>