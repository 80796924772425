<template>
    <div id="FAQs" class="container">
    <div class="faq-container">
        <h2>Frequently Asked Questions</h2>
      <div v-for="(item, index) in faqs" :key="index" class="faq-item">
        <div class="faq-question" @click="toggle(index)">
          <h2>{{ item.question }}</h2>
          <span class="toggle-indicator" v-if="activeIndex === index">-</span>
          <span class="toggle-indicator" v-else>+</span>
        </div>
        <transition name="slide-fade">
          <div class="faq-answer" v-if="activeIndex === index">
            <p>{{ item.answer }}</p>
          </div>
        </transition>
      </div>
    </div>
</div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        activeIndex: null,
        faqs: [
          {
            question: "What are the check-in and check-out times?",
            answer: "Our check-in time is from [insert check-in time], and check-out time is by [insert check-out time]."
          },
          {
            question: "Is parking available?",
            answer: "Yes, we provide complimentary parking for our guests.."
          },
          {
            question: "Are pets allowed?",
            answer: "Unfortunately, we do not allow pets in our bed and breakfast. We apologise for any inconvenience caused."
          },
          {
            question: "Is breakfast included in the room rate?",
            answer: "Unfortunately, we do not allow pets in our bed and breakfast. We apologise for any inconvenience caused."
          },
          {
            question: "Can you assist with arranging transportation or local tours?",
            answer: "Certainly! We can help you with transportation arrangements and provide information on local tours and attractions. Just let us know your preferences, and we will be glad to assist you."
          },
          {
            question: "Do you offer any special packages or discounts?",
            answer: "Yes, we offer special packages and discounts throughout the year. Keep an eye on our website or contact us directly to learn about any current promotions or seasonal offers."
          },
          {
            question: "Is there a minimum age requirement for guests?",
            answer: "Yes, we have a minimum age requirement of 18. Please note that guests must meet the minimum age criteria to stay at our bed and breakfast."
          },
          // more questions and answers here...
        ]
      };
    },
    methods: {
      toggle(index) {
        this.activeIndex = this.activeIndex === index ? null : index;
      }
    }
  };
  </script>
  
  <style scoped>
   .container {
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 2%
}
  .faq-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 10%;
  }
  
  .faq-item {
    border-radius: 10px;
    box-shadow: 2px 4px 12px rgba(0,0,0,.08);
    overflow: hidden;
  }
  
  .faq-question {
    background-color: white;
    padding: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .faq-question h2 {
    margin: 0;
    font-size: 1.2rem;
    color: #333;
  }
  
  .faq-answer {
    padding: 15px;
    background-color: #fff;
  }
  
  .toggle-indicator {
    font-size: 1.2rem;
    color: #333;
  }
  
  /* Transition effect */
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(10px);
    opacity: 0;
  }
  </style>
  