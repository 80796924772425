<template>
    <div id="info-section">
      <div class="container">
        
    <section class="things-to-know">
      <h2 class="title">Things to Know</h2>
      <div class="columns">
        <div class="column">
          <h3>House Rules</h3>
          <!-- Add your house rules here -->
          <p><i class="fas fa-clock"></i> Check-in: 15:00 - 21:00</p>
          <p><i class="fas fa-clock"></i> Check-out: Before 10:00</p>
          <p><i class="fas fa-volume-mute"></i> Quiet Hours: 22:00 - 06:30</p>
          <p><i class="fas fa-dog"></i> No Pets</p>
          <p><i class="fas fa-glass-cheers"></i> No Parties or Events</p>
          <p><i class="fas fa-smoking-ban"></i> No Smoking indoors</p>
          <p><i class="fas fa-toilet-paper"></i> Organic Waste & toilet paper only in toilet</p>
        </div>
        <div class="column">
          <h3>Safety & Property</h3>
          <h4>Safety Considerations</h4>
          <p><i class="fas fa-child"></i> Not suitable for children and infants</p>
          <p><i class="fas fa-water"></i> Nearby lake, river, other body of water</p>
          <h4>Safety devices</h4>
          <p><i class="fas fa-shield-alt"></i> Carbon monoxide alarm installed</p>
          <p><i class="fas fa-smoke"></i> Smoke alarm installed</p>
          <h4>Property info</h4>
          <p><i class="fas fa-cat"></i> Pets live on property: We have a Jack Russle and three cats.</p>
        </div>
        <div class="column">
          <h3>Cancellation Policy</h3>
          <!-- Add your cancellation policy here -->
          <p><i class="fas fa-calendar-times"></i> Cancellation Policy Info 1</p>
          <p><i class="fas fa-calendar-times"></i> Cancellation Policy Info 2</p>
        </div>
      </div>
    </section>
    </div>
    </div>
  </template>
  
  <script>
export default {
  name: 'ThingsToKnow',
};
</script>
  
<style scoped>
.container {
  max-width: 1080px;
  margin: 0 auto;
  padding: 80px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  
}
  .title {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 30px;
  }
  
  .things-to-know {
    padding: 20px;
  }
  
  .columns {
    display: flex;
    justify-content: space-around;
    text-align: left;;
    flex-wrap: wrap; /* this allows the flex items to wrap onto the next line */
    
  }
  
  .column {
    flex: 1;
    margin: 10px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    background-color: #7db0984d;
  }
  
  .column h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .column p {
    font-size: 1.1rem;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  
  .column i {
    margin-right: 5px;
  }

  @media (max-width: 768px) {
    .column {
      flex-basis: 100%; /* the flex-basis property sets the initial main size of a flex item. It sets the size of the content box unless otherwise set with box-sizing */
      margin-bottom: 20px;
    }
  }
</style>