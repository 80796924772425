<template>
  <div id="book">
    <div class="container">
      <div class="booking-calendar">
        <h2 style="padding-top: 5%" class="stay">Book Your Stay</h2>
        <form action="https://formspree.io/f/xoqzzron" method="POST">
          <div class="form-column">
            <h2>Personal Information</h2>
              <div class="form-group">
                <label for="name">Name</label>
                <input type="text" id="name" v-model="name" name="name" required />
              </div>
              <div class="form-group">
                <label for="email">Email Address</label>
                <input type="email" id="email" v-model="email" name="email" required />
              </div>
              <div class="form-group">
                <label for="telephone">Telephone</label>
                <input type="tel" id="telephone" v-model="telephone" name="telephone" required />
              </div>
          </div>
          <div class="calendar-column">
            <h2>Select Dates</h2>
            <DatePicker range v-model="selectedDate" lang="en"/>
            <input type="hidden" name="selectedDate" :value="formattedDate">
            <div class="form-group">
              <label for="message">Your Message</label>
              <textarea id="message" v-model="message" rows="5" name="message"></textarea>
            </div>
          </div>
          <div class="cta-container">
            <button type="submit" class="cta">Check Availability</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import VueDatepickerUi from 'vue-datepicker-ui';

export default {
  name: "BookingCalendar",
  components: {
    DatePicker: VueDatepickerUi
  },
  data() {
    return {
      selectedDate: [new Date(), new Date(new Date().getTime() + 9 * 24 * 60 * 60 * 1000)],
      name: "",
      email: "",
      telephone: "",
      message: "",
    };
  },
  computed: {
    formattedDate() {
      const checkIn = this.formatDate(this.selectedDate[0]);
      const checkOut = this.formatDate(this.selectedDate[1]);
      return `Checkin: ${checkIn} Checkout: ${checkOut}`;
    }
  },
  methods: {
    formatDate(date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
  },
};
</script>


<style scoped>
.container {
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  border-radius: 25px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.booking-calendar form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.stay {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 30px;
  width: 100%;
}

.calendar-column,
.form-column {
  flex: 1 1 100%;
  max-width: calc(50% - 20px);
  padding: 20px;
  box-sizing: border-box;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button.cta {
  text-decoration: none;
  padding: 10px 20px;
  background-color: #7DB098;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
}

button.cta:hover {
  background-color: #7DB098;
}

.cta-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .booking-calendar form > div {
    max-width: 100%;
  }
}
</style>
