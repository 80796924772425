import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import 'bootstrap/dist/css/bootstrap.css';
import * as VueGoogleMaps from "vue3-google-map";
import VueDatepickerUi from 'vue-datepicker-ui'
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMapMarkerAlt, faCompass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faMapMarkerAlt, faCompass);
app.component('font-awesome-icon', FontAwesomeIcon);

// create an instance of the Vue application
const app = createApp(App)

app.use(VueGoogleMaps, {
    load: {
      key: "AIzaSyBINoKMfr5LNGvFahHJM6meyGmvlKrRsz4", // replace this with your Google Maps API key
      libraries: "places", // necessary for places input
    },
});
 // Use v-calendar, optionally providing defaults


app.use(router).mount('#app')
app.component('DatePicker', VueDatepickerUi)
